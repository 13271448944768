// colors
$color-primary: #20bead !default;
$color-text-main: #1f2939 !default;
$color-text-secondary: #3d4d69 !default;
$color-label-text: rgba(61, 77, 105, 0.7) !default;
$color-placeholder-text: rgba(61, 77, 105, 0.7) !default;
$color-white: #fff !default;

// hover colors
$hover-color-primary: #1dac9c !default;

// border colors
$border-primary: #20bead !default;
$border-secondary: #c2cce1 !default;
$border-secondary-light: #e4e7eb !default;

// border hover colors
$border-secondary-light-hover: #88e0d7 !default;

// input box shadow
$input-hover-shadow: 0 0 0 4px #eaf9f7 !default;

//Body
body {
  font-size: 14px;

  ::selection {
    background: #d7dce2;
  }
}

body:not(.modal-open) {
  padding: 0% !important;
}

.fixed-top {
  position: sticky;
  top: 0;
  border-width: 0 0 1px;
  border-radius: 0;
  right: 0;
  left: 0;
}

.onboard-link-color {
  color: #176ffc !important;
  float: right;
  font-weight: bold;
}

.onboarding-cross-button {
  background: #fff;
  border: 1px solid #e4e7eb;
  color: #20bead;

  &:hover {
    background-color: #20bead !important;
    border: 1px solid #20bead !important;
    color: #fff !important;
  }
}

.add-organization-artwork-container {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f8f9;
  height: 100vh;
}

// login and register stying for short time
.link-color {
  color: #3b90f4 !important;
  margin: 30px 5px 0 !important;
  text-decoration: underline;
}

.login-artwork {
  background-image: url("../img/common/background.svg");
  background-size: cover;
  height: 100vh;
}

.login-img-container {
  margin: 0;
  justify-content: center;
  align-items: center;
  padding-top: 20vh;
}

.auth-artwork-text {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 24px;
  margin-top: -3vh;
  max-width: 70%;
}

.link-register {
  color: #66615b;
  font-size: 17px;
}

.fontAwesome {
  font-family: Helvetica, "FontAwesome", sans-serif;
}

.login-button {
  background: linear-gradient(to right, #33ccae 0%, #00a99d 100%);
  height: 49px;
  font-size: 20px;
  font-weight: 400;
}

.register-button {
  background: linear-gradient(to right, #33ccae 0%, #00a99d 100%);
  height: 49px;
  font-size: 20px;
  font-weight: 400;
}

select {
  background-color: #0082d8;
  height: 100px;
}

//Custom button style

.buttonStyle {
  background-color: #109c88;
}

.login-form {
  margin-top: 25px;

  .form-group {
    margin-bottom: 15px;
  }
}

.show-password-icon {
  width: 32px;
  position: absolute;
  cursor: pointer;
  left: 88%;
}

.register-form {
  .form-group {
    margin-bottom: 18px;
  }
}

.link-forgot {
  margin-bottom: 20px !important;
  color: #666;
}

.register {
  padding: 10px;
  margin: 20px 10px 15px;
  background: #fff;
}

.list_button {
  width: 10px;
  height: 10px;
}

@media (max-width: 1200px) {
  #leftImg {
    display: none !important;
  }
}

// .inputLogin{
//    background-color: #66615B;
// }

.loginRight {
  width: 493px;
  height: 300px;
  margin-left: 100px;
  margin-top: 162px;
}

//sidebar no horizontal scroll
.sidebar .nav li>a {
  margin: 0px 0px 0px !important;
}

.copyright {
  color: black !important;
}

.custom-loader .modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-top: 30vh;
}

.custom-loader .modal-body {
  align-self: center !important;
}

//common
.remove-default-mt {
  margin-top: 0px !important;
}

.remove-default-mb {
  margin-bottom: 0px !important;
}

.remove-default-uppercase {
  text-transform: none !important;
}

.replace-border-primary {
  border: 1px solid #51cbce !important;
}

.replace-border-default {
  border: 1px solid #66615b !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.span-mr-5 {
  margin-right: 5px;
}

.font-bold .modal-title,
.font-bold {
  font-weight: bold !important;
}

.form-control {
  font-size: 13px;
  margin-top: 0;
  padding: 5px;
  height: auto;
}

textarea.form-control {
  padding: 5px 10px !important;
}

.red-error {
  color: red !important;
}

.table-status-style {
  padding: 5px 10px;
  color: white;
  border-radius: 2px;
}

.table-header-style {
  font-size: 10px;
}

//Activity screenshot
.span-screenshot-ml {
  color: #0082d8;
  margin-left: 5px;
}

.span-screenshot-ml:hover {
  color: blue;
  cursor: pointer;
}

.icon-screenshot-mr {
  margin-right: 5px;
}

.total-hours {
  color: #0082d8;
}

.total-hours-text {
  color: #8f9fae;
}

.get-started-modal .modal-body {
  padding: 20px 30px !important;
}

.get-started-modal {
  max-width: 600px !important;
}

.gray-color {
  color: #a8b5c0;
}

//Activity Apps
.project-data-table {
  margin-top: 25px;
}

//Profile
.profile-edit-organizations {
  margin-top: 0 !important;
}

.default-email-button {
  color: #6c6c6c;
  margin: -6px 10px 0px 30px;
  padding: 4px 10px;
  height: 35px;
  width: 120px;
  font-size: 16px;

  &:hover {
    color: #6c6c6c !important;
    background: #d2d7db !important;
    border: 1px solid #d2d7db !important;
  }
}

//Timesheet Daily
.hideText {
  visibility: hidden;
}

// .today-button-mt{
//     margin-top: 58px !important;
// }

.time_picker {
  width: 300px;
  border: 1px solid hsl(0, 0%, 80%);
  height: 38px;
  background-color: #fff;
  margin-left: 5px;
  margin-top: 0;
  padding: 10px;
  border-radius: 4px;
}

.button-time {
  margin-top: 25px;
  width: 90px;
  padding: 0;
  height: 40px;
  font-size: 14px;
}

.button-add {
  margin-top: 45px;
  width: 175px;
  padding: 0;
  height: 47px;
  font-size: 14px;
  margin-left: 10px;
}

@media (max-width: 400px) {
  .time_picker {
    width: 190px;
    border: none;
    height: 47px;
    background-color: #e9edf4;
  }

  .memberSearch {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .button-time {
    width: 65px;
    text-align: center;
    font-size: 10px;
  }

  .button-add {
    width: 78px;
    text-align: center;
    font-size: 10px;
  }

  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 29px !important;
    width: 209px !important;
  }

  .AddManual {
    text-align: center !important;
    margin-bottom: 20px;
  }
}

.rdt.rdtOpen .rdtPicker {
  opacity: 1;
  visibility: visible;
  margin-top: 29px !important;
  width: 306px;
}

.span-timesheet-daily {
  color: #0082d8;
}

.span-timesheet-daily:hover {
  color: blue;
  cursor: pointer;
}

//Timesheet weekly
.today-button-mt-weekly {
  margin-top: 28px !important;
}

.add-time-modal {
  max-width: 750px !important;
}

.add-time-modal .modal-body {
  padding: 20px 30px !important;
}

.add-time-modal .close,
.add-project-modal .close {
  margin-top: -45px !important;
}

.add-time-footer {
  padding: 5px 15px !important;

  button {
    border-radius: 3px !important;
    background-color: #aaaaaa !important;
    text-transform: capitalize;
    width: 120px;
    font-weight: 700;
    height: 47px;
    font-size: 14px;
  }
}

//allNotes-modal

.allNotes-modal {
  max-width: 1050px !important;
}

.allNotes-modal {
  .modal-content {
    width: 90%;
    margin: 0 auto;
  }

  .modal-body {
    padding: 20px 30px !important;

    .quantity-notes {
      border-radius: 50%;
      font-size: 15px;
      text-align: center;
      width: 25px;
      height: 22px;
      color: #ffffff;
    }
  }
}

.date {
  width: 100%;
  height: 30px;
  border: none !important;
  // background-color:'#e9edf4' !important,
  background-color: #e9edf4 !important;
}

.AddManual {
  text-align: left;
}

.modalLabel {
  text-align: center !important;
  margin-top: 10px;
}

.modalReason {
  text-align: center !important;
  margin-top: 51px !important;
}

.textAreaManual {
  background-color: #e9edf4 !important;
  border: none !important;
  font-size: 15px;
}

//allNotes-modal

.addMember-modal {
  max-width: 900px !important;
}

.addMember-modal .modal-body {
  padding: 40px 70px !important;
}

//

.top-buffer {
  text-align: justify;
  margin-bottom: 20px;
  width: 56px;
  height: 30px;
}

.LabelModalInvite {
  //margin-top: 33px;
  font-size: 14px;
}

.emailInput {
  width: 100%;
  height: 33px;
  background-color: rgb(240, 241, 243);
  border: none;
}

.emailInput:hover {
  border: solid 1px rgb(190, 190, 190) !important;
  border-radius: 0% !important;
}

@media (max-width: 768px) {
  .top-buffer {
    text-align: justify;
    margin-top: 30px;
  }
}

@media (min-width: 1300px) {
  .pricingResponsiveShow {
    float: left;
  }

  .memberModalText {
    font-size: 0px !important;
  }
}

//Approvals
.manage-members {
  margin-top: 10px;
}

//Project
.editProject {
  font-size: 16px;
  color: #666;
}

.editProject:hover {
  cursor: pointer;
}

//pricing

.pricingItem {
  font-size: 20px;
  margin-left: 5px;
}

.fontPricing {
  color: rgb(24, 226, 226);
  text-shadow: 1px 1px 1px #ccc;
  font-size: 1.5em;
}

.listgroup {
  text-align: justify;
}

.PricingButton {
  background-color: rgb(16, 187, 187);
  height: 50px;
  font-size: 18px;
}

//Pricing Icon styling

.pricingIcon {
  color: rgb(14, 168, 168);
  font-weight: lighter;
}

.pricingIconNot {
  color: rgb(138, 138, 138);
}

@media only screen and (max-width: 768px) {
  .today-button-mt {
    margin-top: 10px !important;
  }

  .add-time-modal {
    max-width: 600px !important;
  }

  .manage-members {
    margin-top: 0px;
    float: left !important;
  }
}

// // Nav link styling

.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item .nav-link.active:hover,
.nav-tabs .nav-item .nav-link.active:focus {
  background-color: transparent;
  border-bottom: solid 2px #00a99d;
  //cursor: pointer;
}

.nav-tabs .nav-item .nav-link .active {
  :hover {
    cursor: pointer;
  }
}

//memberModalText

.memberModalText {
  font-size: 20px;
}

.block-pdg {
  padding: 25px;
}

.nav-bar-user-avatar-box {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 15px;

  img {
    width: 100% !important;
    height: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}

.user-avatar-box {
  width: 85px;
  height: 85px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 15px;

  img {
    width: 100% !important;
    height: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}

.username {
  font-size: 16px;
  color: #4d4d4d;
}

.inline-form-view {
  margin: 30px 0 0;
}

.inline-form-label,
.form-control {
  color: #6c6c6c !important;
  font-size: 15px !important;
  display: inline-block;
  text-transform: none;

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #4caf50;
    cursor: pointer;
    border-radius: 50%;
  }
}

.save-button {
  margin-top: 25px;
  font-size: 15px !important;
  font-weight: normal !important;
}

.static {
  color: #bcbcbc !important;
}

.static .inline-form-label {
  color: #bcbcbc !important;
}

.static div {
  font-size: 15px !important;
}

.change_picture input {
  width: 90px;
}

/*
This is only to temporally solve firefox image upload issue. Should be solved properly later.
*/
.change_picture>div:nth-child(2)>input:nth-child(1) {
  font-size: 15px;
}

input[type="file"] {
  outline: none;
  font-size: 0;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &::before {
    content: "Browse";
    display: inline-block;
    border-radius: 3px;
    padding: 5px 20px;
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    background: linear-gradient(to right, #32cbae 0%, #0190aa 100%);
    color: #fff;
  }

  &:hover::before {
    border-color: black;
  }

  &:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
}

.project_status_span {
  padding: 7px;
  border-radius: 3px;
  text-transform: capitalize;
  display: inline-block;
  height: 35px;
  width: 84px;
  text-align: center;
}

.report-date-span {
  padding: 7px;
  border-radius: 3px;
  text-transform: capitalize;
  display: inline-block;
  text-align: center;
}

.archive_btn {
  background: none;
  border: 1px solid #666;
  color: #666;
  margin-left: 10px !important;
  height: 35px;
  padding: 10px 22px;
}

.table_header {
  color: #666;

  .header_line {
    border-bottom: 1px solid #dcdbdb;

    th {
      text-transform: capitalize;
    }
  }

  .org-plan {
    padding-top: 13px;
  }
}

.header_line {
  border-bottom: 1px solid #dcdbdb;
  letter-spacing: 0.035em;

  th {
    text-transform: capitalize !important;
    color: #7c7c7c !important;
  }
}

.btn-outline-secondary[disabled],
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #ffffff !important;
}

.btn-outline-secondary[disabled],
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled,
.disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;

  &:hover {
    cursor: not-allowed !important;
  }
}

.rdt {
  position: relative;

  .styled-calendar {
    width: 100%;
  }
}

.calendar {
  color: #00a99d;
  position: absolute;
  right: 10px;
  bottom: 6px;
  display: block;
  height: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1550px) and (min-width: 1200px) {
  .fa-calendar:before {
    content: none;
  }
}

.modal-sm-title {
  font-size: 14px;
}

.table-responsive {
  overflow: auto;
}

th {
  letter-spacing: 0.03em;
  color: #595757;
}

.apps-data-table {
  tbody {
    td {
      color: #000;
      border-bottom: 1px solid #dee2e6 !important;
    }
  }
}

.dashboardTable {
  //height: 530px;
  min-height: 407px;
  height: auto;
}

.screenshot-laptop-icon {
  position: relative;
  right: 2px;
  max-width: 35%;
  top: 3px;
}

.apps-data-body {
  max-height: 250px;
  overflow-x: auto;
}

.tb-wrapper {
  .table-responsive {
    overflow-x: auto !important;
    max-height: 75px;
  }
}

.tb-wrapper {
  overflow-x: initial !important;
}

cancel-btn {
  background: #aaa;
  border-radius: 0;
  width: 110px;
  height: 40px;
}

.timesheet_time_picker {
  height: 40px;
  margin-top: 0 !important;
  background: #fff !important;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  width: 100%;
  margin-left: 0 !important;
  color: #808080;
}

.manual-time-time-picker {
  height: 40px;
  margin-top: 0 !important;
  background: #fcfdfe !important;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  width: 100%;
  margin-left: 0 !important;
  color: #2f394e;

  &::placeholder {
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }
}

.weekly-modal-header {
  text-align: left;
  padding-bottom: 0;
  border-bottom: 0;
  padding-left: 50px;
}

.close-btn-img {
  border: none;
  background: transparent;
  text-align: right;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  width: 30px;
  float: right;
  position: relative;
  top: 0px;
  z-index: 20;
}

.carousel-inner img {
  position: relative;
  top: -38px;
}

.btn-wrapper {
  padding: 0;

  button {
    background: #20bead;
    height: 30px;
    border-radius: 3px;
    line-height: 9px;
  }
}

.message-quantity {
  font-size: 9px;
  padding: 1px 6px !important;
  background-color: #dc3545 !important;
}

.textArea {
  width: 100%;
  padding: 10px;
  height: 75px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  resize: none;
}

.manualForm {
  .invalid-feedback {
    display: block;
  }
}

.weekday-checkbox {
  margin-right: 35px;

  input {
    margin-top: 25px;
    width: 20px;
    height: 20px;
  }
}

.allWeek-checkbox {
  margin-bottom: 20px;

  input {
    width: 20px;
    height: 20px;
    margin: 0 20px 0.5rem 0;
  }

  label {
    margin-left: 30px;
  }
}

.carousel-control-prev-icon {
  cursor: pointer;
  background-image: url("../img/left-arr.svg");
}

.carousel-control-next-icon {
  cursor: pointer;
  background-image: url("../img/right-arr.svg");
}

.scheduleForm {
  .invalid-feedback {
    display: block;
  }
}

.wrapper-dotted-text {
  display: flex;
  align-items: center;
  padding: 19px 7px !important;

  .dotted-text {
    text-overflow: ellipsis;
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }

  button {
    margin-bottom: 0 !important;
    padding: 5px 15px;
  }
}

.btn-container {
  display: flex;
  align-items: center;

  button {
    margin-bottom: 0 !important;
    background: #fff !important;
    border: 1px solid #7a7979;
    color: #7a7979;
    padding: 5px 15px;
  }
}

.nav-tabs-cont {
  .nav-tabs {
    .nav-link {
      cursor: pointer !important;
      border-bottom: 2px solid #fff;

      &::after {
        border: none !important;
        content: "";
      }

      &::before {
        border: none !important;
        content: "";
      }
    }
  }
}

.btn-popup {
  font-size: 14px !important;
}

.members_button {
  width: 135px !important;
  background: linear-gradient(to right,
      rgb(50, 203, 174) 0%,
      rgb(1, 144, 170) 100%);
}

.lds-spinner {
  //color: official;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 80px;
  left: 84px;
  width: 25px;
  height: 14px;
  border-radius: 60%;
  background: linear-gradient(to right,
      rgb(50, 203, 174) 0%,
      rgb(1, 144, 170) 100%);
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loader {
  width: 100%;
  height: auto;
  text-align: center;
  position: absolute;
  top: 40%;

  p {
    margin-top: 140px;
    color: rgb(51, 204, 174);
    font-size: 20px;
  }
}

.client-modal {
  button {
    font-size: 14px;
    padding: 0 !important;
  }
}

.organization-modal {
  button {
    &:last-child {
      width: 250px;
    }
  }
}

.invalidInput {
  border: 2px solid #ef505c;
}

.alert-login-page {
  background: #fadcde !important;
  color: #666;
  font-size: 18px;
  margin-top: 15px;
  text-align: left;
}

.success-active {
  width: 100%;
  height: auto;
  text-align: center;
  position: absolute;

  //top: 40%;
  p {
    margin-top: 140px;
    color: rgb(51, 204, 174);
    font-size: 30px;
  }
}

.timezone {
  .css-10nd86i {
    font-size: 15px !important;
  }
}

.forgotPasswordPage {

  .form-group .input-group-prepend .input-group-text,
  .form-group .input-group-append .input-group-text,
  .input-group .input-group-prepend .input-group-text,
  .input-group .input-group-append .input-group-text {
    padding: 10px !important;
  }

  .input-group .form-control:last-child,
  .input-group-btn:last-child>.dropdown-toggle,
  .input-group-btn:first-child>.btn:not(:first-child) {
    margin: 0;
  }
}

.gm-style-iw.gm-style-iw-c {
  min-width: 250px !important;
  padding: 0px !important;
  box-shadow: 2px 0 6px 3px rgba(170, 183, 211, 0.36) !important;

  .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-ui-hover-effect {
    top: 0px !important;
    right: 2px !important;

    span {
      height: 16px !important;
      width: 16px !important;
    }
  }
}

// .gm-style-iw.gm-style-iw-c {
//   max-width: 654px;
//   max-height: 206px;
//   padding: 18px;
//   background: #fff;
//   padding-top: 0;
//   padding-left: 0;
//   padding-right: 0;
//   .gm-style-iw-d {
//     overflow: auto !important;
//     .calendar-pop-inner {
//       padding: 10px 25px 10px 20px;
//       background: #fff;
//       display: flex;
//       align-items: center;
//       width: 100%;
//       margin: 0;
//       p {
//         margin-bottom: 0;
//       }
//       & + div {
//         padding: 15px 20px 1px 20px;
//         background: #f2f3f4;
//         margin-bottom: 20px;
//       }
//       .col {
//         padding-left: 0;
//       }
//     }
//   }
// }
.default-header {
  margin-left: 15px;
  font-size: 24px;
  color: #2f394e;
}

.jsx-693531783 {
  border-radius: 15px;
  //background-color: gray;
  width: 100%;
}

.stripeCard {
  .card-header {
    width: 70%;
    margin: 0 auto;
    margin-top: 22px;
    color: #808080;
  }

  .card-body {
    padding: 5px 14px 12px 15px !important;
    flex: none !important;
  }

  .card-footer {
    width: 67.5%;
    margin: 0 auto;
    padding: 0 !important;
  }

  .stripeBtn {
    background: linear-gradient(to right,
        rgb(51, 204, 174) 0%,
        rgb(0, 169, 157) 100%);
    width: 100px;
    margin-top: 5px;
  }

  .stripeElem {
    width: 70%;
    margin: 0 auto;
    border: 1px solid #ddd;
    height: 40px;
    padding: 10px;
    border-radius: 6px;
  }

  label {
    margin-left: 150px;
  }
}

.btn-disable {
  button {
    color: grey !important;
  }
}

.disable-button {
  &:focus {
    background: #fff !important;
    color: grey !important;
  }
}

.btn-spec-hover {
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 700 !important;

  &:hover {
    background: linear-gradient(to right, #2ebfa5 0%, #008796 100%);
  }
}

.nav-tabs .nav-link::after {
  border: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  content: "";
}

.nav-tabs .nav-link::before {
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  content: "";
}

.nav-tabs .nav-item .nav-link {
  cursor: pointer !important;
  border-bottom: 2px solid #fff;
}

.border-inp {
  border: 1px solid;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.border-top-none {
  border-top: none !important;
}

.schedule-modal {
  .close {
    display: none !important;
  }
}

#contact_part {
  select {
    background: none !important;
  }
}

.react-phone-number-input__icon {
  width: 2.74em;
  height: 2.43em;
  border: none;

  .react-phone-number-input__icon-image {
    margin-top: 5px !important;
  }
}

.btnDashboardSeeMore {
  background: #20bead;
  border-radius: 3px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  font-size: 13px;
  font-weight: 500;
}

.allTasks-modal {
  max-width: 80% !important;
}

.custom-header-button {
  align-self: flex-start;
  background: #20bead;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: normal;
  margin-top: 0;
  border-radius: 3px;
}

.content-header-wrapper {
  display: flex;
}

.search-bar {
  background-color: #ffffff;
  border: 1px solid #c1c1c1;
}

.activity-report-card-column {
  padding-left: 30%;
}

.screenshot-notes-btn {
  width: 100% !important;
}

.dashboard-hours-img {
  padding: 3px 40px 8px 2px;
}

.payment-rate-th {
  width: 220px;
}

.lg-outer .lg-inner {
  top: -75px;
}

.lg-backdrop {
  background-color: #444444;
}

.lg-outer .lg-image {
  max-height: 70%;
}

.lg-sub-html {
  background-color: rgba(43, 42, 42, 0.85);
  padding: 10px 106px;
  display: flex;
}

.lg-outer .lg-thumb-outer {
  background-color: #444444;
}

.lg-outer .lg-toggle-thumb {
  background-color: #444444;
  right: 0;
  left: 20px;
}

.lg-actions {

  .lg-next,
  .lg-prev {
    margin-top: -90px;
  }
}

.screenshot-desc-title {
  font-size: 13px !important;
  font-weight: bold;
  letter-spacing: 0.03rem;
  margin-right: 5px !important;
}

.screenshot-desc-text {
  font-size: 13px !important;
}

.custom-tab-style {
  justify-content: flex-start;
  padding-left: 17px;
}

#minimizeSidebar {
  background-color: #00a99d !important;
}

#minimizeSidebar:active {
  background-color: #33ccae !important;
}

#minimizeSidebar:hover {
  background-color: #33ccae !important;
}

.Toastify__toast {
  min-height: 50px !important;
  border-radius: 8px !important;
  box-shadow: 0 20px 70px 0 rgba(194, 204, 222, 0.6);
  padding-left: 15px;
}

.Toastify__toast--default {
  background: #62be96 !important;
  color: #fff !important;
  font-size: 14px;
}

.Toastify__toast--info {
  background: #62be96 !important;
  color: #fff !important;
  font-size: 14px;
}

.Toastify__toast--success {
  background: #62be96 !important;
  color: #fff !important;
  font-size: 14px;
}

.Toastify__toast--warning {
  background: #d2a309 !important;
  color: #fff !important;
  font-size: 14px;
}

.Toastify__toast--error {
  background: #ea6969 !important;
  color: #fff !important;
  font-size: 14px;
}

.Toastify__toast-container--bottom-right {
  bottom: 4em !important;
  width: fit-content;
}

//react-big-calendar and monthly timesheet
.rbc-month-header {
  background: #f6f8f9;
  height: 45px;

  @media screen and (max-width: 1600px) {
    max-height: 32px;
  }
}

.rbc-header {
  color: $color-label-text;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  span {
    font-size: 13px;
    font-weight: 600;
  }
}

.rbc-header+.rbc-header {
  border-left: none;
}

.rbc-off-range-bg {
  background: white;
}

.rbc-off-range {
  color: rgba(61, 77, 105, 0.3);
}

.rbc-month-view {
  border: 1px solid #e1e5e8;
  margin-top: 20px;
  border-radius: 6px;
  overflow: visible;
}

.rbc-date-cell {
  font-size: 14px;
  text-align: center;
  padding-top: 4px;
  a{
    cursor: default;
  }
  @media screen and (max-width: 1600px) {
    font-size: 12px !important;
  }
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 1px solid #e1e5e8;
}

.rbc-month-row {
  border-top: 1px solid #e1e5e8 !important;
  overflow: visible;
}

.rbc-today {
  background-color: white;
}

.rbc-date-cell.rbc-now {
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-weight: 600;
    width: 30px;
    height: 30px;
    padding: 5px 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;

    @media screen and (max-width: 1600px) {
      width: 22px;
      height: 22px;
    }
  }
}

.rbc-row-segment {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rbc-row-bg {
  overflow: visible !important;
}

.monthly-timesheet-time {
  font-size: 14px;
  height: 36px;
  width: 90px;
  margin-top: 20px;
  padding: 0;
  cursor: default;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: none;

  @media screen and (max-width: 1600px) {
    max-height: 24px;
    margin-top: 12px;
  }
}

.monthly-timesheet-timespan {
  margin-right: 6px;
}

.monthly-timesheet-total {
  margin: 0;
  background: #d7dce2;
  text-align: center;
  border-radius: 3px;
  padding: 6px;
  width: 170px;
  float: right;
}

.rbc-row-content {
  z-index: 0;
}

.rbc-event.rbc-selected {
  background-color: white;
}

.rbc-event {
  &:focus {
    outline: none;
  }
}

.monthly-timesheet-header {
  font-size: 20px;
}

.monthly-timesheet-calendar {
  height: 600px;
}

.monthly-timesheet-round-btn {
  &:hover {
    background-color: #0e877d !important;
  }

  &:focus {
    background-color: #00a99d !important;
  }
}

//Bi-weekly timesheet
.seven-cols {

  .col-md-1,
  .col-sm-1,
  .col-lg-1 {
    flex: 0 0 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714%;
  }
}

.biwk-timesheet-date {
  text-align: center;
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  color: #666;
}

.biwk-timesheet-hour {
  display: flex;
  justify-content: center;
  height: 35px;
  width: 100%;
  margin-bottom: 20px;
}

.biwk-timesheet-hour-span {
  min-width: 130px;
  text-align: center;
  border: 2px solid #d3d3d3;
  height: 35px;
  border-radius: 9px;
  padding-top: 5px;
  font-weight: normal;
  font-size: 16px;
}

.biwk-timesheet-date-time {
  margin-top: 15px;
}

.biwk-timesheet-date-range {
  color: #666;
}

.biwk-timesheet-total {
  margin: 0;
  background: #d7dce2;
  text-align: center;
  border-radius: 9px;
  padding: 6px;
  width: 140px;
  float: right;
}

.clock-icon {
  margin-left: 5px;
  color: rgba(50, 168, 170, 0.9);
}

//-----End Bi-weekly timesheet

//React-dates
.DateRangePicker {
  height: 40px;
  width: 100%;
  max-width: 100%;
  border-radius: 6px;
  // width: fit-content;
}

.DateRangePickerInput {
  height: 36px;
  width: 90%;
  display: flex;
  border-radius: 6px;
  border: 1px solid $border-secondary-light;

  &:hover {
    border-color: $border-secondary-light-hover;
    box-shadow: $input-hover-shadow;
  }

  &:focus {
    border-color: $border-primary;
    outline: none;
    box-shadow: $input-hover-shadow;
  }
}

.DateInput {
  height: 36px;
  width: 100px;
  margin-left: 5px;
  margin-top: 1px;
}

.DateInput_input {
  height: 36px;
  font-size: 13px;
  color: $color-text-main;
}

.DateInput_input:focus-visible {
  outline: none !important;
}

.DateInput_input__focused {
  border-bottom: 2px solid $color-primary;
}

.CalendarMonth_caption {
  color: $color-primary;
}

.DayPicker_weekHeader {
  color: $color-primary;
}

.DateRangePickerInput_calendarIcon {
  margin: 0 5px 0 auto;
}

.CalendarDay {
  font-size: 13px;
}

.CalendarDay__default {
  border: 1px solid $border-secondary-light;
}

.CalendarDay__selected {
  background: $color-primary;
  border: 1px double $color-primary;
}

.CalendarDay__selected_span {
  background: #4cdbcb;
  border: 1px double #3bcfbe;
}

.date-range-footer {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px 15px;
}

//-----Single Date Picker
.SingleDatePicker {
  width: 100%;
  height: 40px;
  border-radius: 6px;
}

.SingleDatePickerInput {
  // width: 100%;
  height: 40px;
  display: flex;
  border-radius: 6px;
  border: 1px solid $border-secondary-light;
  margin-top: 1px;

  &:hover {
    border-color: $border-secondary-light-hover;
    box-shadow: $input-hover-shadow;
  }

  &:focus {
    border-color: $border-primary;
    outline: none;
    box-shadow: $input-hover-shadow;
  }

  .DateInput {
    height: 38px;
    margin: 0;
    border-radius: 6px;
    width: 100px !important;
    padding-left: 5px;

    .DateInput_input__disabled {
      background-color: #fcfdfe;
      cursor: not-allowed;
      font-style: normal;
    }
  }

  .DateInput__disabled {
    background-color: #fcfdfe;
    cursor: not-allowed;
  }

  .DateInput_input {
    height: 38px;
    // width: 200px;
    font-size: 13px;
    color: $color-text-main;

    &::placeholder {
      color: $color-placeholder-text;
    }

    .CalendarMonth_caption {
      color: $color-primary;
    }

    .DayPicker_weekHeader {
      color: $color-primary;
    }
  }

  .DateInput_input__focused {
    border-bottom: 2px solid $color-primary;
  }

}

// Visualizing the "Select Deadline" Placeholder
.deadline {
  .SingleDatePicker {
    .DateInput_input {
      width: 140px;
    }
  }
}

.SingleDatePickerInput__disabled {
  cursor: not-allowed;
  background-color: #fcfdfe;
  font-style: normal;

  .SingleDatePickerInput_calendarIcon {
    cursor: not-allowed;
  }
}

.SingleDatePickerInput_calendarIcon {
  margin: 0 5px;
  // padding-top: 6px;
  margin-left: auto !important;
}

.SingleDatePickerInput_arrow {
  margin: 10px 7px;
  font-size: 16px;
}

.SingleDatePicker_picker {
  z-index: 2;
}

.error .SingleDatePicker .SingleDatePickerInput__withBorder {
  border: 1px solid #EF717D;
  background-color: rgba(241, 87, 98, 0.05);
}

.error .SingleDatePicker .DateInput_input {
  background-color: rgba(241, 87, 98, 0.05);
}

.timesheetPanel {
  .SingleDatePicker {
    height: 28px;
  }
  .SingleDatePickerInput {
    height: 28px;
    margin-top: 0px;
    .DateInput {
      height: 26px;
    }
  }
  .DateInput_input {
    height: 26px;
    font-size: 11px;
  }
}

//-----End React-dates

//---Black Friday Page
.offer-navbar {
  background: white;
  border-bottom: 2px solid #f6f6f6;
  z-index: 10;
  position: fixed;
  width: 100%;

  .nav-item {
    margin-right: 15px;
    font-weight: bold;
    color: #283b5c;
    cursor: pointer;

    &:hover {
      .nav-link {
        color: white !important;
        transform: translateY(-1px);
        background-color: #3db3a9;
        border-radius: 5px;
      }
    }
  }
}

.offer-navbar-container {
  width: 80%;
}

.black-friday-wrapper {
  padding-top: 30px;
}

.black-friday-image {
  width: 75%;
  margin-top: -40px;
}

.discount-info {
  color: #283b5c;
  font-size: 22px;
  text-align: center;
  padding: 0px 20px;
}

.discount-page-video {
  margin-top: 120px;
}

.countdown-timer-units {
  font-weight: bold;
}

.navbar .navbar-toggler {
  height: 37px;
  padding-right: 34px;
  padding-left: 5px;
}

//---End Black Friday Page

//Settings Page
.settings-button {
  background: #20bead;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px;
  font-weight: normal;
}

//Billing
.go-back-button {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.settings-content-header {
  font-size: 20px;
  margin: 0;
  color: #3b3f47;
}

.upgrade-plan-card {
  padding: 30px 40px;
  border: 1px solid #c2cce1;
  border-radius: 10px;
  box-shadow: none;
}

// ---- Settings Page end

//Progress Bar
.progress-vertical {
  width: 10px;
  min-height: 145px;
  display: flex;
  align-items: flex-end;
  margin-right: 0px;
  float: left;
  border-radius: 5px;
  background: rgba(181, 181, 181, 0.3);
}

.progress-bar-vertical {
  width: 100%;
  height: 0;
  border-radius: 5px;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}

.progress-bar {
  border-radius: 5px;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}

//new dashboard
.clock-icon-white {
  filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(0%) hue-rotate(52deg) brightness(108%) contrast(101%);
}

//navbar trial info
.trial-info {
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
}

//workspace
.progress-bar-workspace {
  background: #20bead !important;
}

//React Phone Input
.react-tel-input {
  font-family: "Montserrat", sans-serif;
}

.phone-number-input {
  height: 40px !important;
  width: 100% !important;
  border: 1px solid $border-secondary-light !important;

  &:hover {
    border-color: $border-secondary-light-hover !important;
    box-shadow: $input-hover-shadow;
  }

  &:focus {
    border-color: $border-primary !important;
    box-shadow: $input-hover-shadow;
  }
}

.phone-number-flag-dropdown {
  background-color: #f6f6f6 !important;
  border: 1px solid $border-secondary-light !important;
}

// onboarding phone input
.onboarding-phone-number-input {
  height: 60px !important;
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid $border-secondary !important;
  border-radius: 0px !important;
  font-size: 20px !important;
  color: $color-text-main !important;

  &:hover {
    border-color: $border-secondary-light-hover !important;
  }

  &:focus {
    border-color: $border-primary !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px !important;
  }

  @media screen and (max-width: 500px) {
    font-size: 16px !important;
  }
}

.onboarding-phone-number-flag-dropdown {
  background-color: #fff !important;
  border: none !important;
  border-bottom: 1px solid $border-secondary !important;
  border-radius: 0 !important;
}

// navbar and sidebar
.nav-org-dropdown {
  display: flex;
  align-items: center;
  min-width: 200px;
  height: 42px;
  border: 1px solid #c2cce1;
  border-radius: 8px;
  padding: 6px 10px;
}

.nav-org-dropdown-menu {
  width: 100%;
  top: 8px !important;
  left: 10px !important;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #e4e7eb !important;
  color: #2f394e !important;
}

.nav-selected-org {
  width: 100%;
  padding: 0;
  font-size: 14px !important;
  color: #2f394e !important;
  display: flex;
  align-items: center;
}

.nav-selected-org[aria-expanded="true"] {
  .nav-org-arrow {
    transform: rotate(180deg);
    transition: all 300ms ease 0s;
  }
}

.nav-selected-org[aria-expanded="false"] {
  .nav-org-arrow {
    transform: rotate(0deg);
    transition: all 300ms ease 0s;
  }
}

//Accordion
/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  display: grid;
  grid-template-columns: 90% 35px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: #3d4d69;
  cursor: pointer;
  padding: 15px;
  border: 1px solid #e4e7eb;
  border-radius: 10px;
  outline: none;
}

.accordion:focus {
  outline: none;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: #eaf9f7;
  border: 1px solid transparent;
}

/* Style the accordion content title */
.accordion__title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #3d4d69;
  margin: 5px 0;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  /* background-color: white; */
  overflow: auto;
  transition: max-height 0.6s ease;
  overflow: hidden;
}

/* Style the accordion content text */
.accordion__text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px 18px 18px 0px;
  /* color: rgba(47, 57, 78, 0.7); */
  color: #3d4d69;
}

/* Fix tab content style for accordion css */
.tab-content>.active {
  background: transparent;
}

// checkout
.paddle-checkout {
  display: none;
  width: 100%;
}

.paddle-checkout.loaded {
  display: block;
  animation: 0.4s ease 0s 1 normal forwards running fadeIn;
}

// modal opacity
.modal-backdrop.show {
  opacity: 0.1;
}


.blurred-screenshot-image {
  filter: blur(4px) !important;
}

.blurred-screenshot-thumbnail {
  filter: blur(1px);
}